import React from "react"
import { Link } from "gatsby-plugin-react-intl";
import { StyledService } from "./Service.styled"

const Service = ({ icon, title, slug }) => (
  <StyledService>
    <Link to={slug}>
      <img src={icon} alt={title} />
      <h3>{title}</h3>
    </Link>
  </StyledService>
)

export default Service
