// extracted by mini-css-extract-plugin
export var sectionHome = "home-module--section--home--ALafV";
export var container = "home-module--container--TUxf-";
export var videoBg = "home-module--video-bg--AS+I2";
export var scrollDown = "home-module--scroll-down--LOQJ0";
export var sectionAbout = "home-module--section--about--JlR7w";
export var sectionServices = "home-module--section--services--CYNtR";
export var sectionServices__row = "home-module--section--services__row--fjGaF";
export var services = "home-module--services--MbBMm";
export var sectionRealizations = "home-module--section--realizations--zPByn";
export var sectionRealizations__textColumn = "home-module--section--realizations__text-column--5Hdnx";
export var sectionRealizations__galleryColumn = "home-module--section--realizations__gallery-column--Idz1-";
export var realizations = "home-module--realizations--fhyEw";
export var realization = "home-module--realization--uuipL";