import styled from "@emotion/styled"

export const StyledService = styled.div`
  margin: 0 0 2.5rem;

  @media (min-width: 992px) {
    margin: 0 0 3.125rem;
  }

  img {
    display: block;
    height: 50px;
    margin: 0 0 .625rem;
    transition: transform .3s;
  }

  h3 {
    margin: 0;
    color: #414141;
    font-family: "Poppins", sans-serif;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;

    @media (max-width: 575px) {
      font-size: .875rem;
    }

    @media (min-width: 576px) {
      font-size: 1rem;
    }
  }

  a {
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    align-items: center;
    text-decoration: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: calc(100% - 50px);
      left: 50%;
      width: 0;
      height: 0;
      background: radial-gradient(ellipse at center,rgba(0,0,0,.35) 0,transparent 80%);
      transform: translateX(-50%);
      transition: width .3s, height .3s;
    }

    &:hover {
      &::after {
        width: 50px;
        height: 5px;
      }

      img {
        transform: translateY(-15px);
      }
    }
  }
`
