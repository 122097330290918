import * as React from "react";
import slugify from "slugify";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { injectIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Service from "../components/Service";
import ContactSection from "../components/ContactSection";
import {
  sectionHome,
  videoBg,
  container,
  scrollDown,
  sectionAbout,
  sectionServices,
  sectionServices__row,
  services,
  sectionRealizations,
  sectionRealizations__textColumn,
  sectionRealizations__galleryColumn,
  realizations,
  realization
} from "../assets/style/pages/home.module.scss";

const HomePage = ({ data, intl }) => {
  const sectionMainData = data.cockpitHome.sectionMain.value;
  const sectionAboutData = data.cockpitHome.sectionAbout.value;
  const sectionServicesData = data.cockpitHome.sectionServices.value;
  const sectionRealizationsData = data.cockpitHome.sectionRealizations.value;

  const servicesData = data.allCockpitServices.edges.map(({ node }, i) => ({
    ...node,
    slug: slugify(data.servicesPL.edges[i].node.title.value, { lower: true })
  }));

  const images = data.allCockpitRealizations.edges.map(({node}, i) => {
    return (
      <div className={`col-12 col-md-6 col-lg-4 ${realization}`} key={node.id}>
        <GatsbyImage image={getImage(node.image.value)} alt={node.title.value !== '0' ? node.title.value : `UTS Czapnik ${i}`} />
      </div>
    )
  })

  return (
    <Layout>
      <Seo lang={intl.locale} title="home" />
      <section className={`section ${sectionHome}`}>
        <video className={videoBg} muted autoPlay loop>
          <source src={sectionMainData.background.value.url} type="video/mp4" />
        </video>
        <div className={`container ${container}`}>
          <p>{sectionMainData.intro.value}</p>
          <h1>{sectionMainData.title.value}</h1>
          <p>{sectionMainData.text.value}</p>
        </div>
        <a href="#about" className={`smooth-scroll ${scrollDown}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c3.309 0 6 2.691 6 6v8c0 3.309-2.691 6-6 6s-6-2.691-6-6v-8c0-3.309 2.691-6 6-6zm0-2c-4.418 0-8 3.582-8 8v8c0 4.418 3.582 8 8 8s8-3.582 8-8v-8c0-4.418-3.582-8-8-8zm0 9c-.829 0-1.5-.672-1.5-1.5s.671-1.5 1.5-1.5 1.5.672 1.5 1.5-.671 1.5-1.5 1.5z" /></svg>
        </a>
      </section>
      <section id="about" className={`section ${sectionAbout}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 col-xl-4">
              <h2 className="section__title" dangerouslySetInnerHTML={{ __html: sectionAboutData.title.value }}></h2>
            </div>

            <div className="col-12 col-md-9 col-lg-9 col-xl-7 col-xxl-6 offset-xl-1">
              <p dangerouslySetInnerHTML={{ __html: sectionAboutData.text.value }}></p>
              <Link to="/o-firmie/" className="button">{intl.formatMessage({ id: "readmorebtn" })}</Link>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className={`section ${sectionServices}`}>
        <div className="container">
          <div className={`row ${sectionServices__row}`}>
            <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 order-lg-last">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-12 order-md-last order-lg-first">
                  <h2 className="section__title" dangerouslySetInnerHTML={{ __html: sectionServicesData.title.value }}></h2>
                </div>

                <div className="col-12 col-md-8 col-lg-12 order-md-first order-lg-last">
                  <p dangerouslySetInnerHTML={{ __html: sectionServicesData.text.value }}></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-lg-first">
              <div className={`row ${services}`}>
                {
                  servicesData.map(({ id, icon, title, slug }) => (
                    <div key={id} className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4">
                      <Service icon={icon.value.url} title={title.value} slug={`/${slug}`} />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="realizations" className={`section ${sectionRealizations}`}>
        <div className="container">
          <div className="row">
            <div className={`col-12 col-xl-5 col-xxl-4 ${sectionRealizations__textColumn}`}>
              <div className="row">
                <div className="col-12 col-md-5 col-lg-6 col-xl-12">
                  <h2 className="section__title" dangerouslySetInnerHTML={{ __html: sectionRealizationsData.title.value }}></h2>
                </div>

                <div className="col-12 col-md-7 col-lg-6 col-xl-12">
                  <p dangerouslySetInnerHTML={{ __html: sectionRealizationsData.text.value }}></p>
                  <Link to="/realizacje/" className="button">{intl.formatMessage({ id: "seemorebtn" })}</Link>
                </div>
              </div>
            </div>

            <div className={`col-12 col-xl-7 offset-xxl-1 ${sectionRealizations__galleryColumn}`}>
              <div className={`row ${realizations}`}>
                {images}
              </div>
              <Link to="/realizacje/" className="button">{intl.formatMessage({ id: "seemorebtn" })}</Link>
            </div>
          </div>
        </div>
      </section>
      <ContactSection/>
    </Layout>
  )
}

export const query = graphql`
  query($lng: String) {
    cockpitHome(lang: { eq: $lng }) {
      sectionMain {
        value {
          intro {
            value
          }
          title {
            value
          }
          text {
            value
          }
          background {
            value {
              url
            }
          }
        }
      }
      sectionAbout {
        value {
          title {
            value
          }
          text {
            value
          }
        }
      }
      sectionServices {
        value {
          title {
            value
          }
          text {
            value
          }
        }
      }
      sectionRealizations {
        value {
          title {
            value
          }
          text {
            value
          }
        }
      }
    }

    allCockpitServices(filter: {lang: {eq: $lng}}) {
      edges {
        node {
          id
          icon {
            value {
              url
            }
          }
          title {
            value
          }
        }
      }
    }

    servicesPL: allCockpitServices(filter: {lang: {eq: "pl"}}) {
      edges {
        node {
          title {
            value
          }
        }
      }
    }

    allCockpitRealizations(limit: 6, filter: {lang: {eq: $lng}}, sort: {fields: cockpitCreated, order: DESC}) {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED,
                  width: 545,
                  height: 260,
                  placeholder: BLURRED
                )
              }
            }
          }
          title {
            value
          }
        }
      }
    }
  }
`

export default injectIntl(HomePage)
